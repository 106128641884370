import {countDays, currentDate} from '@mp/common/utils/date';
import {toDateString} from '@mp/common/utils/converters';
import {service} from '@mp/common/api';
import {Spice} from '../types';

interface SpiceDto {
    id: number;
    name: string;
    deadline: string;
}

export function loadSpicesService(): Promise<Array<Spice>> {
    return service.get<Array<SpiceDto>>('spices').then(({data}) =>
        data.map((raw: SpiceDto): Spice => {
            const date: Date = new Date(raw.deadline);
            return {
                id: raw.id,
                name: raw.name,
                deadline: toDateString(date, 'mm.yyyy'),
                overdue: countDays(date, currentDate())
            };
        })
    );
}
