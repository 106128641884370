import {SelectOption} from '@mp/common/components/form-input/types';
import {CalendarEventType} from '../types';

const CALENDAR_EVENT_TYPE_TO_NAME_MAP: Map<CalendarEventType, string> = new Map<CalendarEventType, string>([
    [CalendarEventType.BIRTHDAY, 'Urodziny'],
    [CalendarEventType.NAMEDAY, 'Imieniny'],
    [CalendarEventType.EVENT, 'Wydarzenie']
]);

export function resolveEventTypeOptions(): Array<SelectOption> {
    const elements: Array<SelectOption> = [];
    CALENDAR_EVENT_TYPE_TO_NAME_MAP.forEach((name: string, key: CalendarEventType) => {
        elements.push({name, value: key});
    });
    return elements;
}

export function resolveEventName(event: CalendarEventType, noEventName: boolean = false): string {
    if (noEventName && event === CalendarEventType.EVENT) {
        return '';
    }
    return CALENDAR_EVENT_TYPE_TO_NAME_MAP.get(event) ?? event;
}
