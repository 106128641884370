import {AxiosResponse} from 'axios';
import {service, ServiceResponseWithError} from '@mp/common/api';
import {toTimeStringHM, toFullMonthNameYear} from '@mp/common/utils/date';
import {toDateString, toDateTime} from '@mp/common/utils/converters';
import {lastElement, isNotEmpty, firstElement} from '@mp/common/utils/array';
import {toBoolean} from '@mp/common/utils/converters';
import {round, toCurrency} from '@mp/common/utils/number';
import {getWorkingDaysCount} from './getWorkingDaysCount';
import {DateSection, WorkDayType} from '../types';
import {ElapsedTime} from '../utils/elapsedTime';

export interface WorkDayDataDTO {
    id: string;
    user_id: string;
    time_in: string;
    time_out: string;
    last: string;
    hourlyRate: number;
    type: WorkDayType;
}

export interface WorkDayData {
    id: string;
    date: string;
    time_in: string;
    time_out: string;
    last: boolean;
    earned: number;
    hourlyRate: number;
    type: WorkDayType;
    fullDateTimeIn: Date;
    fullDateTimeOut: Date;
    workTimeMilliseconds: number;
}

export interface WorkMonthData {
    title: string;
    elapsedOverallTime: ElapsedTime;
    elapsedOverallExpectedTime: ElapsedTime;
    overallTimeEarnings: string;
    earningsPerDay: number;
    lastDayIsOut: boolean;
    lastDayId: string;
    lastDayHourlyRate: number;
    workDays: Array<WorkDayData>;
}

export function loadHourService(dateSection: DateSection, employerId: number): Promise<ServiceResponseWithError<WorkMonthData>> {
    const startString: string = toDateString(dateSection.start, 'yyyy-mm-dd HH:MM:SS');
    const endString: string = toDateString(dateSection.end, 'yyyy-mm-dd HH:MM:SS');

    return service
        .get<Array<WorkDayDataDTO>>('workHours.load', {data: {start: startString, end: endString, employerId}})
        .then((response: AxiosResponse<Array<WorkDayDataDTO>>) => {
            let millisecondsInWork: number = 0;

            const workDaysData: Array<WorkDayData> = response.data.map((d) => {
                const {hourlyRate, type} = d;
                const timeIn: Date = toDateTime(d.time_in);
                const timeOut: Date = !!d.time_out ? toDateTime(d.time_out) : null;

                let elapsedTime: number = (timeOut?.getTime() || new Date().getTime()) - timeIn.getTime();
                if (elapsedTime < 0) {
                    elapsedTime = 0;
                }

                millisecondsInWork += elapsedTime;

                const result: WorkDayData = {
                    id: d.id,
                    date: toDateString(timeIn),
                    time_in: toTimeStringHM(timeIn),
                    time_out: timeOut ? toTimeStringHM(timeOut) : '',
                    last: toBoolean(d.last),
                    earned: totalEarning(elapsedTime, hourlyRate),
                    type,
                    hourlyRate,
                    fullDateTimeIn: timeIn,
                    fullDateTimeOut: timeOut,
                    workTimeMilliseconds: elapsedTime
                };
                return result;
            });

            if (isNotEmpty(workDaysData)) {
                const firstDayOfBillingCycle: Date = new Date(firstElement(workDaysData).date);
                const lastDayOfBillingCycle: Date = new Date(lastElement(workDaysData).date);
                const overallExpectedDays: number = getWorkingDaysCount(firstDayOfBillingCycle, lastDayOfBillingCycle);
                const overallEarnings: number = workDaysData.map(({earned}) => earned).reduce((a, b) => a + b);

                const workMonthData: WorkMonthData = {
                    title: toFullMonthNameYear(firstDayOfBillingCycle),
                    lastDayIsOut: !!lastElement(workDaysData).time_out,
                    elapsedOverallTime: new ElapsedTime(millisecondsInWork),
                    elapsedOverallExpectedTime: new ElapsedTime(overallExpectedDays * 8 * 60 * 60 * 1000),
                    overallTimeEarnings: toCurrency(overallEarnings),
                    earningsPerDay: overallEarnings / overallExpectedDays,
                    workDays: workDaysData,
                    lastDayId: lastElement(workDaysData).id,
                    lastDayHourlyRate: lastElement(workDaysData).hourlyRate
                };
                return {data: workMonthData};
            } else {
                const workMonthData: WorkMonthData = {
                    title: null,
                    lastDayIsOut: true,
                    elapsedOverallTime: null,
                    elapsedOverallExpectedTime: null,
                    overallTimeEarnings: toEarnedMoney(-1, 0),
                    earningsPerDay: null,
                    workDays: [],
                    lastDayId: null,
                    lastDayHourlyRate: null
                };
                return {data: workMonthData};
            }
        });
}

function totalEarning(milliseconds: number, earnings: number): number {
    if (milliseconds < 0) {
        // local time could be a little different from that on server
        return 0;
    }
    const minutes: number = Math.floor(milliseconds / 1000 / 60);
    const hours: number = Math.floor(minutes / 60);
    const value: number = earnings;

    return round(hours * value + ((minutes % 60) / 60) * value, 2);
}

function toEarnedMoney(milliseconds: number, earnings: number): string {
    return `${toCurrency(totalEarning(milliseconds, earnings))}`;
}
