import './_formInput.scss';
import React, {forwardRef, useImperativeHandle, useRef, MutableRefObject} from 'react';
import classNames from 'classnames';
import {booleanToString, toBoolean, toDateInput, toDateTimeInput, toFloat, toTimeInput} from '../../utils/converters';
import {MultiSelect} from '../../../../components/multi-select/ui/MultiSelect';
import {DateTimePicker} from '../../../../components/date-time-picker';
import {MultiOption} from '../../../../components/multi-select/types';
import {switchGuard} from '../../utils/switchGuard';
import {InputType, SelectOption} from './types';
import {isEmpty} from '../../utils/array';
import {Icon, SvgIcon} from '../../svg';
import {KeyboardCode} from '../../types';

const FORM_INPUT: 'form-input' = 'form-input';

type ReturnType = 'string' | 'number' | 'boolean' | 'date';

export interface Validation {
    digitsOnly?: boolean;
    minLength?: number;
    maxLength?: number;
}

interface FormInputProps {
    id: string;
    description: string;
    inputType: InputType;
    defaultValue: unknown;
    selectOptions: Array<SelectOption>;
    validate: Validation;
    disabled: boolean;
}

export interface FormInputRef {
    getInputReturnType: () => ReturnType;
    getValue: () => string;
    isValid: () => boolean;
    getId: () => string;
}

export const FormInput = forwardRef((props: FormInputProps, ref) => {
    useImperativeHandle(ref, (): FormInputRef => ({getValue, isValid, getInputReturnType, getId}));

    const {inputType, validate, defaultValue, selectOptions, disabled, description} = props;

    const errorElementRef: MutableRefObject<HTMLDivElement> = useRef(null);
    const textElementRef: MutableRefObject<HTMLInputElement> = useRef(null);

    const formInputClass: string = classNames(FORM_INPUT, {
        [`${FORM_INPUT}-hidden`]: inputType === 'hidden'
    });

    return (
        <div className={formInputClass}>
            <div className={`${FORM_INPUT}-description`}>{description}</div>
            <div className={`${FORM_INPUT}-input`}>
                {renderInput()}
                <div className="validation-error" ref={errorElementRef} />
            </div>
        </div>
    );

    function getId(): string {
        return props.id;
    }

    function isValid(): boolean {
        switch (inputType) {
            case 'text':
            case 'text-area':
            case 'text-number':
                return isTextBoxValid();
            case 'checkbox':
            case 'hidden':
            case 'select':
            case 'select-null':
            case 'select-number':
            case 'date':
            case 'date-time':
            case 'time':
            case 'date-time-picker':
                return true;
            case 'multi-select':
                return isMultiselectValid();
            default:
                switchGuard(inputType);
        }
    }

    function getInputReturnType(): ReturnType {
        switch (inputType) {
            case 'hidden':
            case 'text-number':
            case 'select-number':
                return 'number';
            case 'checkbox':
                return 'boolean';
            case 'date':
                return 'date';
            default:
                return 'string';
        }
    }

    function getValue(): string {
        switch (inputType) {
            case 'text':
            case 'text-area':
                return (textElementRef.current as HTMLInputElement)?.value || '';
            case 'hidden':
                return (textElementRef.current as HTMLInputElement)?.value || null;
            case 'text-number':
                const stringValue: string = (textElementRef.current as HTMLInputElement)?.value;
                return toFloat(stringValue) == null ? null : toFloat(stringValue) + '';
            case 'checkbox':
                return booleanToString((textElementRef.current as HTMLInputElement).checked);
            case 'select':
                return (textElementRef.current as unknown as HTMLSelectElement).value + '';
            case 'select-null':
            case 'select-number':
                return (textElementRef.current as unknown as HTMLSelectElement).value || null;
            case 'multi-select':
                return JSON.stringify((textElementRef.current as unknown as MultiSelect).getValues());
            case 'date-time-picker':
                return (textElementRef.current as unknown as DateTimePicker).getDateString();
            case 'date':
            case 'date-time':
                return (textElementRef.current as HTMLInputElement).value;
            case 'time':
                return toTimeInput((textElementRef.current as HTMLInputElement).value);
            default:
                switchGuard(inputType);
        }
    }

    function isMultiselectValid(): boolean {
        if (!validate) {
            return true;
        }
        const multiSelect: MultiSelect = textElementRef.current as unknown as MultiSelect;

        if (multiSelect.getValues().length >= validate.minLength) {
            errorElementRef.current.innerText = '';
            return true;
        } else {
            errorElementRef.current.innerText = `Wymagana ilość elementów: ${validate.minLength} `;
        }
    }

    function isTextBoxValid(): boolean {
        const validation: Validation = validate as Validation;
        const textElementValue: string = (textElementRef.current as HTMLInputElement).value?.trim();
        if (validation) {
            const errorMessages: Array<string> = [];
            if (validation.minLength != null) {
                if (textElementValue.length < validation.minLength) {
                    errorMessages.push(`Minimalna ilość znaków: ${validation.minLength}`);
                }
            }
            if (validation.maxLength != null) {
                if (textElementValue.length > validation.maxLength) {
                    errorMessages.push(`${textElementValue.length}/${validation.maxLength} znaki`);
                }
            }
            if (!!validation.digitsOnly) {
                if (isNaN(Number(textElementValue)) || textElementValue == null || textElementValue === '') {
                    errorMessages.push(`"${textElementValue}" nie jest liczbą`);
                }
            }
            const _isValid: boolean = isEmpty(errorMessages);
            if (_isValid) {
                errorElementRef.current.innerText = '';
            } else {
                errorElementRef.current.innerText = errorMessages.join('<br/>');
            }
            return _isValid;
        }
        return true;
    }

    function resolveStringValue(): string {
        if (defaultValue === 0) {
            return '0';
        }
        if (defaultValue == null) {
            return null;
        }
        return (defaultValue || '') as string;
    }

    function renderInput(): JSX.Element {
        const defaultStringValue: string = resolveStringValue();

        switch (inputType) {
            case 'text':
                return (
                    <>
                        <input
                            ref={textElementRef}
                            type="text"
                            autoCapitalize="none"
                            defaultValue={defaultStringValue}
                            disabled={disabled}
                        />
                        {renderClearButton()}
                    </>
                );
            case 'text-number':
                return (
                    <>
                        <input
                            ref={textElementRef}
                            type="text"
                            inputMode="decimal"
                            defaultValue={defaultStringValue}
                            onKeyPress={preventNotNumbersOrNotDot}
                            disabled={disabled}
                        />
                        {renderMinusButton()}
                        {renderClearButton()}
                    </>
                );
            case 'text-area':
                return (
                    <textarea
                        ref={textElementRef as unknown as MutableRefObject<HTMLTextAreaElement>}
                        defaultValue={defaultStringValue}
                        disabled={disabled}
                    />
                );
            case 'checkbox':
                return <input ref={textElementRef} type="checkbox" defaultChecked={toBoolean(defaultStringValue)} disabled={disabled} />;
            case 'hidden':
                return <input ref={textElementRef} type="hidden" defaultValue={defaultStringValue} />;
            case 'select':
            case 'select-null':
            case 'select-number':
                return renderSelectElement(defaultStringValue);
            case 'multi-select':
                return (
                    <MultiSelect
                        ref={textElementRef as unknown as MutableRefObject<MultiSelect>}
                        defaultValues={defaultValue as Array<string>}
                        options={selectOptions as Array<MultiOption>}
                        disabled={disabled}
                    />
                );
            case 'date-time-picker':
                return (
                    <DateTimePicker
                        ref={textElementRef as unknown as MutableRefObject<DateTimePicker>}
                        date={defaultValue as Date}
                        disabled={disabled}
                    />
                );
            case 'date':
                return <input ref={textElementRef} type="date" defaultValue={toDateInput(defaultValue as Date)} disabled={disabled} />;
            case 'date-time':
                return (
                    <input ref={textElementRef} type="date-time" defaultValue={toDateTimeInput(defaultValue as Date)} disabled={disabled} />
                );
            case 'time':
                return <input ref={textElementRef} type="time" defaultValue={toTimeInput(defaultValue as string)} disabled={disabled} />;
            default:
                switchGuard(inputType);
        }
    }

    function renderMinusButton(): JSX.Element {
        return (
            <SvgIcon
                icon={Icon.Minus}
                onClick={() => {
                    const textInput: HTMLInputElement = textElementRef.current as HTMLInputElement;
                    const isNegativeNumber: boolean = textInput.value.startsWith('-');

                    if (isNegativeNumber) {
                        textInput.value = textInput.value.slice(1);
                    } else {
                        textInput.value = `-${textInput.value}`;
                    }
                    textInput.focus();
                }}
            />
        );
    }

    function renderClearButton(): JSX.Element {
        return (
            <SvgIcon
                icon={Icon.Xmark}
                onClick={() => {
                    const textInput: HTMLInputElement = textElementRef.current as HTMLInputElement;
                    textInput.value = '';
                    textInput.focus();
                }}
            />
        );
    }

    function preventNotNumbersOrNotDot(event: React.KeyboardEvent<HTMLInputElement>): boolean {
        const rawCharCode: string | number = event.which ?? event.keyCode ?? event.code;
        const charCode: number = Number(rawCharCode);
        const value: string = event.currentTarget.value;

        if (charCode === KeyboardCode.Dot && !value.includes('.')) {
            return true;
        }

        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            event.preventDefault();
            return false;
        }
        return true;
    }

    function renderSelectElement(defaultStringValue: string): JSX.Element {
        return (
            <select
                ref={textElementRef as unknown as MutableRefObject<HTMLSelectElement>}
                defaultValue={defaultStringValue}
                disabled={disabled}
            >
                {selectOptions?.map((option) => {
                    return (
                        <option key={option.value} value={option.value}>
                            {option.name}
                        </option>
                    );
                })}
            </select>
        );
    }
});
