import './_discountCardsPage.scss';
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {QRCodeCanvas} from 'qrcode.react';
import Barcode from 'react-barcode';
import {Router} from '@mp/route';
import {Loading} from '../../components/loading';
import {loadDiscountCardsService} from './services';
import {DiscountCard} from './types';

export function DiscountCardsPage(): JSX.Element {
    const {id} = useParams<{id}>();
    const [cards, setCards] = useState<Array<DiscountCard>>(null);

    useEffect(() => {
        loadDiscountCardsService().then(setCards);
    }, []);

    if (cards == null) {
        return <Loading />;
    }

    const selectedCard: DiscountCard = cards.find((card) => card.id === Number(id));

    return (
        <div className="mp-discount-cards-page">
            {selectedCard ? (
                <Card card={selectedCard} />
            ) : (
                cards.map((card) => <Card key={card.id} card={card} targetId={String(card.id)} />)
            )}
        </div>
    );
}

function Card({card, targetId}: {card: DiscountCard; targetId?: string}): JSX.Element {
    const navigate = useNavigate();
    const {title, cardNumber, color, isQrCode} = card;

    return (
        <div className="card" onClick={() => navigate(Router.getUrlToDiscountCardsPage(targetId))}>
            <div className={`card-title card-${color}`}>{title}</div>
            <div className="card-code">{isQrCode ? <QRCodeCanvas value={cardNumber} /> : <Barcode value={cardNumber} />}</div>
        </div>
    );
}
