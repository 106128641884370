import {SelectOption} from '@mp/common/components/form-input/types';
import {WorkDayType} from '../types';

interface TypeData {
    name: string;
    color: string;
    value: number;
}

const PTO_COLOR: string = '#5489c1';

const WORK_DAY_TYPE_TO_NAME: Map<WorkDayType, TypeData> = new Map([
    [WorkDayType.PTO, {name: 'Urlop', color: PTO_COLOR, value: 1}],
    [WorkDayType.HalfPTO, {name: 'Urlop (pół dnia)', color: PTO_COLOR, value: 0.5}],
    [WorkDayType.Holiday, {name: 'Święto', color: '#dd5353', value: 0}],
    [WorkDayType.SickLeave, {name: 'Chorobowe', color: '#9baa65', value: 0}]
]);

export function getSelectOptions(): Array<SelectOption> {
    const values: Array<SelectOption> = Array.from(WORK_DAY_TYPE_TO_NAME.entries()).map(
        (entry): SelectOption => ({value: entry[0], name: entry[1].name})
    );
    values.unshift({value: null, name: null});
    return values;
}

export function resolveWorkTypeColor(type: WorkDayType): string {
    return WORK_DAY_TYPE_TO_NAME.get(type)?.color ?? null;
}

export function resolveWorkTypeName(type: WorkDayType): string {
    return WORK_DAY_TYPE_TO_NAME.get(type)?.name ?? null;
}

export function resolveWorkTypeValue(type: WorkDayType): number {
    return WORK_DAY_TYPE_TO_NAME.get(type).value;
}
