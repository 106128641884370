import './_workHoursPage.scss';
import {useNavigate, useParams} from 'react-router-dom';
import {NavigateFunction} from 'react-router';
import React from 'react';
import {AxiosError} from 'axios';
import classNames from 'classnames';
import {useNumberQueryParams} from '@mp/common/hooks/useQueryParams';
import {InputData} from '@mp/common/components/form-input/types';
import {Button} from '@mp/common/components/button/ui/Button';
import {ConfirmModal} from '@mp/common/modals/ConfirmModal';
import {FONT_GREEN, FONT_RED} from '@mp/common/css/classes';
import {toDateString} from '@mp/common/utils/converters';
import {FormsModal} from '@mp/common/modals/FormsModal';
import {EMPLOYER_ID_PARAM, Router, WorkHoursTab} from '@mp/route';
import {isEmpty, lastElement} from '@mp/common/utils/array';
import {Icon, SvgButton} from '@mp/common/svg';
import {
    insertStartTimeService,
    loadDateSectionsService,
    loadHourService,
    updateEndTimeService,
    updateWorkHoursService,
    WorkDayData,
    WorkDayDataDTO,
    WorkMonthData
} from './services';
import {TabAction, TabsNavigation} from '../../components/tabs-navigation/ui/TabsNavigation';
import {loadEmployersService} from './services/loadEmployers.service';
import {resolveDateSection} from './ui/utils/resolveDateSection';
import {deleteHourService} from './services/deleteHour.service';
import {getNavigationTabs} from './ui/utils/getNavigationTabs';
import {getEmployerById} from './ui/utils/getEmployerById';
import {ToolsBar} from '../../components/tools-bar';
import {Loading} from '../../components/loading';
import {DateSection, Employer} from './types';
import {getSelectOptions} from './utils/workDayType';
import {SIDE_ROWS_LIMIT, WorkHoursTable} from './ui/workHoursTable';
import {t} from './i18n';
import {WorkHoursTimeOff} from './ui/workHoursTimeOff';
import {ElapsedTime} from './utils/elapsedTime';

interface WorkHoursPageProps {
    tab: WorkHoursTab;
    employerId: number;
    navigate: NavigateFunction;
}

interface WorkHoursPageState {
    isLoaded: boolean;
    isEditModalVisible: boolean;
    monthWorkData: WorkMonthData;
    dateSections: Array<DateSection>;
    sectionIndex: number;
    selectedRow: WorkDayData;
    employers: Array<Employer>;
    showDeleteConfirmationWindow: boolean;
    blockUI: boolean;
    showAllRows: boolean;
    showDetailsInTable: boolean;
    error: AxiosError;
}

export function WorkHoursPage(): JSX.Element {
    const {tab} = useParams<{tab}>();
    const navigate = useNavigate();

    return <WorkHoursPageCC tab={tab} employerId={useNumberQueryParams(EMPLOYER_ID_PARAM) ?? 1} navigate={navigate} />;
}

class WorkHoursPageCC extends React.Component<WorkHoursPageProps, WorkHoursPageState> {
    constructor(props: WorkHoursPageProps) {
        super(props);

        this.state = {
            isLoaded: false,
            showAllRows: false,
            showDeleteConfirmationWindow: false,
            blockUI: false,
            error: null,
            isEditModalVisible: false,
            monthWorkData: null,
            dateSections: [],
            sectionIndex: null,
            selectedRow: null,
            showDetailsInTable: false,
            employers: []
        };

        this.onRowClick = this.onRowClick.bind(this);
        this.onStartClick = this.onStartClick.bind(this);
        this.onEndClick = this.onEndClick.bind(this);
        this.changeDateSection = this.changeDateSection.bind(this);
    }

    public componentDidMount() {
        this.loadData();
    }

    public componentDidUpdate(prevProps: Readonly<WorkHoursPageProps>): void {
        if (this.props.employerId !== prevProps.employerId) {
            this.loadData();
        }
    }

    private loadData(): void {
        this.setState({isLoaded: false, showDeleteConfirmationWindow: false, selectedRow: null});

        loadDateSectionsService(this.props.employerId).then((dateSectionsResponse) => {
            this.setState({error: dateSectionsResponse.error, dateSections: dateSectionsResponse.data});

            if (!dateSectionsResponse.error) {
                const {dateSection, sectionIndex} = resolveDateSection(dateSectionsResponse.data);
                Promise.all([loadEmployersService(), loadHourService(dateSection, this.props.employerId)]).then(
                    ([employersResult, hoursResult]) => {
                        this.setState({
                            isLoaded: true,
                            monthWorkData: hoursResult.data,
                            employers: employersResult.data,
                            error: hoursResult.error,
                            blockUI: false,
                            showAllRows: this.resolveShowAllRows(hoursResult.data.workDays.length),
                            sectionIndex
                        });
                    }
                );
            } else {
                this.setState({
                    isLoaded: true,
                    blockUI: false,
                    showAllRows: this.resolveShowAllRows(this.state.monthWorkData?.workDays?.length)
                });
            }
        });
    }

    public render() {
        if (this.state.error) {
            console.error(this.state.error);
            return <div>Unknown error</div>;
        }

        const {navigate} = this.props;

        if (this.state.isEditModalVisible === false) {
            const {selectedRow, showDeleteConfirmationWindow} = this.state;

            const tabActions: Array<TabAction> = [
                this.props.tab === WorkHoursTab.Table
                    ? {
                          id: 'daysOff',
                          name: 'Urlopy',
                          onClick: () => navigate(Router.getUrlToWorkHoursPage(WorkHoursTab.DaysOff, this.props.employerId))
                      }
                    : {
                          id: 'table',
                          name: 'Godziny',
                          onClick: () => navigate(Router.getUrlToWorkHoursPage(WorkHoursTab.Table, this.props.employerId))
                      }
            ];

            if (this.props.tab === WorkHoursTab.Table) {
                tabActions.unshift({
                    id: 'earningPerDay',
                    name: (
                        <span style={{marginRight: '8px'}}>{!this.state.showDetailsInTable ? 'Pokaż szczegóły' : 'Ukryj szczegóły'}</span>
                    ),
                    onClick: () => this.setState((prev) => ({showDetailsInTable: !prev.showDetailsInTable}))
                });
            }

            return (
                <div className="mp-work-hours-page">
                    <TabsNavigation
                        actions={tabActions}
                        tabs={getNavigationTabs(this.state.employers, this.props.employerId, () => this.setState({selectedRow: null}))}
                    />
                    {this.renderContent()}
                    {showDeleteConfirmationWindow && selectedRow && (
                        <ConfirmModal
                            title={t.deleteHours}
                            question={this.resolveDeleteConfirmationMessage(selectedRow)}
                            handleConfirm={() => deleteHourService(Number(selectedRow.id)).then(({success}) => success && this.loadData())}
                            handleClose={() => this.setState({showDeleteConfirmationWindow: false})}
                        />
                    )}
                </div>
            );
        } else if (this.state.isEditModalVisible === true) {
            return (
                <FormsModal
                    title={t.editHours}
                    mode="update"
                    inputs={this.getInputsData()}
                    handleClose={() => this.setNormalView(false)}
                    onSendForm={(object) => updateWorkHoursService(object).then(() => this.setNormalView())}
                />
            );
        } else {
            return null;
        }
    }

    private resolveDeleteConfirmationMessage(selectedRow: WorkDayData): string {
        const {date, time_in, time_out} = selectedRow;
        let message: string = `${t.deleteConfirmation}: ${date} ${time_in}`;
        if (time_out) {
            message += `-${time_out}`;
        }
        return message;
    }

    private resolveShowAllRows(length: number): boolean {
        return length <= SIDE_ROWS_LIMIT * 2;
    }

    private renderContent(): JSX.Element {
        const {employerId} = this.props;
        const {employers, monthWorkData} = this.state;

        if (!this.state.monthWorkData || !this.state.isLoaded) {
            return <Loading />;
        }

        if (!getEmployerById(employers, employerId)) {
            const firstEmployerId: number = employers[0]?.id;
            if (firstEmployerId) {
                window.location.replace(Router.getUrlToWorkHoursPage(WorkHoursTab.Table, firstEmployerId));
            }
            return <div>{`${t.employerDoNotExist}. Id = ${employerId}`}</div>;
        }

        if (this.props.tab === WorkHoursTab.DaysOff) {
            return <WorkHoursTimeOff employerId={this.props.employerId} />;
        }

        const getElapsedTimeString = (): JSX.Element => {
            const {elapsedOverallTime, elapsedOverallExpectedTime} = monthWorkData;
            if (elapsedOverallTime && elapsedOverallExpectedTime) {
                const overTimesMilliseconds: number = elapsedOverallTime.getMilliseconds() - elapsedOverallExpectedTime.getMilliseconds();
                const overTimes: ElapsedTime = new ElapsedTime(overTimesMilliseconds);

                const elapsedTimeString: string = `${elapsedOverallTime.getElapsedTimeString()} / ${elapsedOverallExpectedTime.getElapsedTimeString()}`;
                const elapsedOvertimesString: string = `(${overTimes.getElapsedTimeString(true)})`;

                return (
                    <div>
                        <span style={{marginRight: '8px'}}>{elapsedTimeString}</span>
                        {overTimes.getAbsMinutes() !== 0 && (
                            <span className={overTimes.isNegative() ? FONT_RED : FONT_GREEN}>{elapsedOvertimesString}</span>
                        )}
                    </div>
                );
            }
            return <div />;
        };

        return (
            <>
                {this.renderToolsBar()}
                {this.renderNavigation()}
                <div className="table-container">
                    <WorkHoursTable
                        monthWorkData={this.state.monthWorkData}
                        selectedRow={this.state.selectedRow}
                        showDetails={this.state.showDetailsInTable}
                        showAllRows={this.state.showAllRows}
                        onExpandClick={() => this.setState({showAllRows: true})}
                        onRowClick={(data) => this.onRowClick(data)}
                    />
                </div>
                {getElapsedTimeString()}
                {!monthWorkData.overallTimeEarnings.startsWith('0') && (
                    <div className="earned">
                        {t.cash} {monthWorkData.overallTimeEarnings}
                    </div>
                )}
                {this.renderStartStopButton()}
            </>
        );
    }

    private setNormalView(reloadData = true): void {
        this.setState({selectedRow: null, isEditModalVisible: false}, () => reloadData && this.loadData());
    }

    private renderToolsBar(): JSX.Element {
        const selectedRow: WorkDayData = this.state.selectedRow;
        if (selectedRow) {
            const toolbarDescription: string = `${selectedRow.date} ${selectedRow.time_in} ${selectedRow.time_out}`;
            return (
                <ToolsBar
                    right1={<SvgButton icon={Icon.TrashCan} onClick={() => this.setState({showDeleteConfirmationWindow: true})} />}
                    description={toolbarDescription}
                    onEditClick={() => this.setState({isEditModalVisible: true})}
                />
            );
        } else {
            return <div style={{height: '40px'}} />;
        }
    }

    private renderStartStopButton(): JSX.Element {
        if (this.isLastSectionSelected()) {
            const {blockUI, monthWorkData} = this.state;

            const renderButton = (title: string, onClick: () => void) => (
                <Button title={title} className="start-stop-button" onClick={onClick} disabled={blockUI} />
            );

            if (monthWorkData.lastDayIsOut) {
                const ind: Date = lastElement(monthWorkData.workDays)?.fullDateTimeIn;
                const shouldRenderStartEnd: boolean = ind && toDateString(ind, 'yyyy-mm-dd') !== toDateString(new Date(), 'yyyy-mm-dd');

                return (
                    <>
                        {renderButton(t.start, () => this.onStartClick())}
                        {shouldRenderStartEnd && renderButton(`${t.start}/${t.end}`, () => this.onStartEndClick())}
                    </>
                );
            }
            return renderButton(t.end, () => this.onEndClick());
        }
        return null;
    }

    private onEndClick(): void {
        this.setState({blockUI: true});
        updateEndTimeService(this.state.monthWorkData.lastDayId).then(() => this.loadData());
    }

    private onStartClick(): void {
        this.handleStartClick();
    }

    private onStartEndClick(): void {
        this.handleStartClick(true);
    }

    private handleStartClick(fullDay?: boolean): void {
        this.setState({blockUI: true});
        insertStartTimeService(this.props.employerId, this.state.monthWorkData.lastDayHourlyRate, fullDay).then(() => this.loadData());
    }

    private onRowClick(row: WorkDayData): void {
        this.setState({selectedRow: row});
    }

    private getInputsData(): Array<InputData<WorkDayDataDTO>> {
        const {id, fullDateTimeIn, fullDateTimeOut, last, hourlyRate, type} = this.state.selectedRow;
        return [
            {displayName: null, id: 'id', type: 'hidden', defaultValue: id},
            {displayName: t.start, id: 'time_in', type: 'date-time-picker', defaultValue: fullDateTimeIn},
            fullDateTimeOut && {displayName: t.end, id: 'time_out', type: 'date-time-picker', defaultValue: fullDateTimeOut},
            {displayName: t.hourlyRate, id: 'hourlyRate', type: 'text-number', defaultValue: hourlyRate},
            {displayName: t.type, id: 'type', type: 'select-null', selectOptions: getSelectOptions(), defaultValue: type},
            {displayName: t.billingCycleEnd, id: 'last', type: 'checkbox', defaultValue: last}
        ];
    }

    private renderNavigation(): JSX.Element {
        return (
            <div className="work-hours-navigation">
                {this.renderNavigationButton(false)}
                <span className="work-hours-navigation__label">
                    {t.billingCycle} ({this.state.sectionIndex + 1})
                </span>
                {this.renderNavigationButton(true)}
            </div>
        );
    }

    private renderNavigationButton(isNext: boolean): JSX.Element {
        const chevronIcon: Icon = isNext ? Icon.ChevronRight : Icon.ChevronLeft;
        const value: number = isNext ? 1 : -1;
        let isDisabled: boolean = false;
        const DISABLED_CLASS: string = ' work-hours-navigation__button-disabled';

        if (isNext) {
            if (this.isLastSectionSelected()) {
                isDisabled = true;
            }
        } else {
            if (this.isFirstSectionSelected()) {
                isDisabled = true;
            }
        }

        return (
            <SvgButton
                icon={chevronIcon}
                onClick={() => this.changeDateSection(value, isDisabled)}
                className={classNames({[DISABLED_CLASS]: isDisabled})}
            />
        );
    }

    private isLastSectionSelected(): boolean {
        const {dateSections} = this.state;
        if (isEmpty(dateSections)) {
            return true;
        }
        return this.state.sectionIndex === dateSections.length - 1;
    }

    private isFirstSectionSelected(): boolean {
        const {dateSections} = this.state;
        if (isEmpty(dateSections)) {
            return true;
        }
        return this.state.sectionIndex === 0;
    }

    private changeDateSection(value: number, isDisabled: boolean): void {
        if (!isDisabled) {
            const newIndex: number = this.state.sectionIndex + value;
            loadHourService(this.state.dateSections[newIndex], this.props.employerId).then((result) =>
                this.setState({
                    sectionIndex: newIndex,
                    selectedRow: null,
                    error: result.error,
                    monthWorkData: result.data
                })
            );
        }
    }
}
