import {service, ServiceResponseWithId} from '@mp/common/api';
import {lastElement} from '@mp/common/utils/array';
import {loadLatestDatesService} from './loadLatestDates.service';

export async function insertStartTimeService(employerId: number, hourlyRate: number, fullDay?: boolean): Promise<ServiceResponseWithId> {
    if (!hourlyRate) {
        const {data} = await loadLatestDatesService(employerId);
        hourlyRate = lastElement(data).hourlyRate;
    }

    return service.post('workHours.addStartTime', {data: {employerId, hourlyRate, fullDay}});
}
