import {AxiosResponse} from 'axios';
import {service, ServiceResponseWithError} from '@mp/common/api';
import {Employer} from '../types';

let cachedResponse: Array<Employer> = null;

export function loadEmployersService(): Promise<ServiceResponseWithError<Array<Employer>>> {
    if (cachedResponse) {
        return Promise.resolve({data: cachedResponse});
    }
    return service.get<Array<Employer>>('workHours.loadEmployers').then((response: AxiosResponse<Array<Employer>>) => {
        cachedResponse = response.data;
        return {data: cachedResponse};
    });
}
