import {MONTH_NAMES} from '../consts';
import {zeroPrefix} from './string';
import {minDigits} from './number';

interface NewDateArgs {
    setDay?: number;
    setMonth?: number;
    setYear?: number;
}

export enum DateDay {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6
}

const NUMBER_TO_DAY_MAP: Map<DateDay, [string, string]> = new Map<DateDay, [string, string]>([
    [DateDay.Monday, ['Poniedziałek', 'Pn']],
    [DateDay.Tuesday, ['Wtorek', 'Wt']],
    [DateDay.Wednesday, ['Środa', 'Śr']],
    [DateDay.Thursday, ['Czwartek', 'Cz']],
    [DateDay.Friday, ['Piątek', 'Pt']],
    [DateDay.Saturday, ['Sobota', 'Sb']],
    [DateDay.Sunday, ['Niedziela', 'Nd']]
]);

export function getDayShortNames(): Array<string> {
    return Array.from(NUMBER_TO_DAY_MAP.values()).map((entry) => entry[1]);
}

export function resolveDayName(date: Date): string {
    return NUMBER_TO_DAY_MAP.get(date.getDay())[0];
}

export function resolveDayShortName(date: Date): string {
    return NUMBER_TO_DAY_MAP.get(date.getDay())[1];
}

export function currentDate(): Date {
    return removeTime(new Date());
}

export function removeTime(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export function daysInMonth(date: Date): number {
    const tempDate: Date = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return tempDate.getDate();
}

export function daysInPreviousMonth(date: Date): number {
    const tempDate: Date = new Date(date.getFullYear(), date.getMonth(), 0);
    return tempDate.getDate();
}

export function firstDayInMonth(date: Date): number {
    const tempDate: Date = new Date(date.getFullYear(), date.getMonth(), 1);
    return tempDate.getDay() || 7;
}

export function getMonthName(date: Date) {
    return MONTH_NAMES[date.getMonth()];
}

export function addDay(date: Date, daysToAdd: number = 1): Date {
    return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() + daysToAdd,
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds()
    );
}

export function addSeconds(date: Date, secondsToAdd: number = 1): Date {
    return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds() + secondsToAdd,
        date.getMilliseconds()
    );
}

export function addMonthAndSetFirstDay(date: Date, monthsToAdd: number = 1): Date {
    return new Date(date.getFullYear(), date.getMonth() + monthsToAdd, 1);
}

export function addYearAndSetFirstDay(date: Date, yearsToAdd: number = 1): Date {
    return new Date(date.getFullYear() + yearsToAdd, date.getMonth(), 1);
}

export function isCurrentMonth(date: Date) {
    if (!date) {
        return false;
    }
    const dateNow = new Date();
    if (date.getFullYear() === dateNow.getFullYear() && date.getMonth() === dateNow.getMonth()) {
        return true;
    }
    return false;
}

export function isCurrentYear(date: Date) {
    if (!date) {
        return false;
    }
    const dateNow = new Date();
    if (date.getFullYear() === dateNow.getFullYear()) {
        return true;
    }
    return false;
}

export function isCurrentDay(date: Date) {
    return isCurrentYear(date) && isCurrentMonth(date) && date.getDate() === new Date().getDate();
}

export function isTheSameDay(date1: Date, date2: Date) {
    return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
}

export function currentDateSec(): string {
    const d: Date = new Date();
    const month: number = d.getMonth() + 1;
    return (
        `${d.getFullYear()}.${zeroPrefix(month)}.${zeroPrefix(d.getDate())}` +
        `_${zeroPrefix(d.getHours())}.${zeroPrefix(d.getMinutes())}.${zeroPrefix(d.getSeconds())}`
    );
}

export function getCurrentDateString(): string {
    return new Date().toISOString().slice(0, 10);
}

export function millisecondsToDays(milliseconds: number): number {
    if (milliseconds === 0) {
        return 0;
    }
    return milliseconds / (1000 * 60 * 60 * 24);
}

export class DateUtils {
    public static isTheSameMonth(date1: Date, date2: Date): boolean {
        return date1.getMonth() === date2.getMonth();
    }

    public static isTheSameYear(date1: Date, date2: Date): boolean {
        return date1.getFullYear() === date2.getFullYear();
    }
}

export function toTimeStringHM(date: Date): string {
    return `${zeroPrefix(date.getHours())}:${zeroPrefix(date.getMinutes())}`;
}

export function toFullMonthNameYear(date: Date): string {
    return `${resolveMonthName(date.getMonth())} ${date.getFullYear()}`;
}

export function resolveMonthName(n: number) {
    return MONTH_NAMES[n];
}

export function copyDate(date: Date, args?: NewDateArgs): Date {
    const tempDate: Date = new Date(date);
    const {setDay, setMonth, setYear} = args ?? {};

    if (Number.isInteger(setDay)) {
        tempDate.setDate(setDay);
    }
    if (Number.isInteger(setMonth)) {
        tempDate.setMonth(setMonth);
    }
    if (Number.isInteger(setYear)) {
        tempDate.setFullYear(setYear);
    }
    return tempDate;
}

export function getCurrentTime(separator: string = ':'): string {
    const today = new Date();
    return `${minDigits(today.getHours())}${separator}${minDigits(today.getMinutes())}${separator}${minDigits(today.getSeconds())}`;
}

export function getCurrentDate(): string {
    const date: Date = new Date();
    return `${date.getFullYear()}-${minDigits(date.getMonth() + 1)}-${minDigits(date.getDate())}`;
}

export function countDays(dateFrom: Date, dateTo: Date): number {
    return Math.ceil((dateTo.getTime() - dateFrom.getTime()) / (1000 * 3600 * 24));
}
