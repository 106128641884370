import {AxiosResponse} from 'axios';
import {service, ServiceResponseWithError} from '@mp/common/api';

interface DateDTO {
    time_in: string;
    hourlyRate: number;
}

export function loadLatestDatesService(employerId: number): Promise<ServiceResponseWithError<Array<DateDTO>>> {
    return service.get<Array<DateDTO>>('workHours.loadLastDates', {data: {employerId}}).then((response: AxiosResponse<Array<DateDTO>>) => ({
        data: response.data
    }));
}
