import {zeroPrefix} from '@mp/common/utils/string';

export class ElapsedTime {
    constructor(private readonly milliseconds: number) {}

    public getElapsedTimeString(withSign?: boolean): string {
        if (withSign) {
            return this.resolveSign() + this.toElapsedTime();
        }
        return this.toElapsedTime();
    }

    public getMilliseconds(): number {
        return this.milliseconds;
    }

    public getAbsMinutes(): number {
        return Math.abs(Math.floor(this.milliseconds / 1000 / 60));
    }

    public isNegative(): boolean {
        return this.milliseconds < 0;
    }

    private resolveSign(): string {
        return this.isNegative() ? '-' : '+';
    }

    private toElapsedTime(): string {
        const minutes: number = this.getAbsMinutes();
        const hours: number = Math.floor(minutes / 60);
        return `${hours}h ${zeroPrefix(minutes % 60)}m`;
    }
}
