import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {GLOBAL_ERROR_ID, GLOBAL_PAGE_CONTENT_CLASS} from '@mp/common/consts';
import {CommonContext, ICommonContext} from '@mp/common/context';
import {UserType} from '@mp/common/types';
import {
    ApartmentExpensesPage,
    BucketListPage,
    BusinessCardsPage,
    CalendarPage,
    CalendarPageState,
    ChecklistPage,
    ChecklistPageState,
    CinemaProgramPage,
    DebtorsPage,
    DiscountCardsPage,
    EpisodesPage,
    ExpensesPage,
    FilesPage,
    GamesPage,
    HomePage,
    InvoicesPage,
    MoviesPage,
    RecipesPage,
    SettingsPage,
    ShoppingListPage,
    SushiPage,
    TravelPaymentsPage,
    WishListPage,
    WorkHoursPage
} from './pages';
import {PUBLIC_ROUTES, RouteName} from '@mp/route';
import {resolveCalendarDates} from './components/calendar/services/utils/resolveCalendarDates';
import {CalendarNearDaysEvents} from './components/calendar/ui/CalendarNearDaysEvents';
import {getUserDataService} from './components/user/service/getUserData.service';
import {CashEnvelopesPage} from './pages/cash-envelopes/ui/CashEnvelopesPage';
import {SubscriptionsPage} from './pages/subscriptions/ui/SubscriptionsPage';
import {WinesRankingPage} from './pages/wines-ranking/ui/WinesRankingPage';
import {NotFoundPage} from './pages/not-found/ui/NotFoundPage';
import {getAppSettings, isAdminOrLoggedUser} from './global';
import {SpicesPage} from './pages/spices/ui/SpicesPage';
import {DartsPage} from './pages/darts/ui/DartsPage';
import {FontsPage} from './pages/fonts/ui/FontsPage';
import {LinksPage} from './pages/links/ui/LinksPage';
import {LoginPage} from './pages/login/ui/LoginPage';
import {Header} from './components/header/Header';
import {Footer} from './components/footer/Footer';
import {Loading} from './components/loading';

interface AppState {
    isLoaded: boolean;
    userType: UserType;

    commonContext: ICommonContext;

    calendarPageState: CalendarPageState;
    checklistPageState: ChecklistPageState;
}

export class App extends React.Component<{}, AppState> {
    private setContext = (commonContext: ICommonContext) => this.setState({commonContext});

    constructor(props: {}) {
        super(props);

        this.state = {
            isLoaded: false,
            userType: null,

            commonContext: {showCalendarNearDates: true},

            calendarPageState: null,
            checklistPageState: null
        };
    }

    public componentDidMount() {
        getUserDataService().then((user) => {
            const {role, calendarDates} = user;

            this.setState({
                userType: role,
                calendarPageState: {calendarDates: resolveCalendarDates(calendarDates), displayedDate: new Date()},
                isLoaded: true
            });
        });
    }

    public render() {
        const {userType, calendarPageState, commonContext} = this.state;

        if (!userType || !this.state.isLoaded) {
            return (
                <>
                    <div id={GLOBAL_ERROR_ID} />
                    <Loading />
                </>
            );
        }

        return (
            <CommonContext.Provider value={{context: commonContext, setContext: this.setContext}}>
                <BrowserRouter>
                    <div id={GLOBAL_ERROR_ID} />
                    {commonContext.showCalendarNearDates && <CalendarNearDaysEvents calendarDates={calendarPageState.calendarDates} />}
                    <Header itemIds={getAppSettings()?.headerItems ?? []} />
                    <div className={GLOBAL_PAGE_CONTENT_CLASS}>
                        <Routes>
                            <Route path={createPath(RouteName.HOME)} element={<HomePage />} />
                            <Route path={createPath(RouteName.LOGIN)} element={<LoginPage />}></Route>
                            <Route path={createPath(RouteName.LINKS)} element={<LinksPage />}></Route>
                            <Route path={createPath(RouteName.CASH_ENVELOPES, ':id?')} element={<CashEnvelopesPage />}></Route>
                            <Route path={createPath(RouteName.FONTS)} element={<FontsPage />}></Route>
                            <Route path={createPath(RouteName.WISH_LIST, ':tab')} element={<WishListPage />} />
                            <Route path={createPath(RouteName.INVOICES)} element={<InvoicesPage />} />
                            <Route path={createPath(RouteName.BUCKET_LIST)} element={<BucketListPage />} />
                            <Route path={createPath(RouteName.SUSHI)} element={<SushiPage />}></Route>
                            <Route path={createPath(RouteName.SHOPPING_LIST, ':tab')} element={<ShoppingListPage />} />
                            <Route path={createPath(RouteName.CINEMA_PROGRAM, ':movieId?')} element={<CinemaProgramPage />} />
                            <Route path={createPath(RouteName.EPISODES, ':tab')} element={<EpisodesPage />} />
                            <Route path={createPath(RouteName.MOVIES, ':tab')} element={<MoviesPage />} />
                            <Route path={createPath(RouteName.EXPENSES, ':tab')} element={<ExpensesPage />} />
                            <Route path={createPath(RouteName.FILES)} element={<FilesPage />}></Route>
                            <Route path={createPath(RouteName.BUSINESS_CARD, ':name?')} element={<BusinessCardsPage />} />
                            <Route path={createPath(RouteName.DISCOUNT_CARDS, ':id?')} element={<DiscountCardsPage />}></Route>
                            <Route path={createPath(RouteName.DEBTORS)} element={<DebtorsPage />}></Route>
                            <Route path={createPath(RouteName.GAMES)} element={<GamesPage />}></Route>
                            <Route path={createPath(RouteName.TRAVEL_PAYMENTS)} element={<TravelPaymentsPage />} />
                            <Route path={createPath(RouteName.WINES_RANKIN)} element={<WinesRankingPage />}></Route>
                            <Route path={createPath(RouteName.WORK_HOURS, ':tab')} element={<WorkHoursPage />}></Route>
                            <Route
                                path={createPath(RouteName.CALENDAR, ':view?')}
                                element={<CalendarPage state={calendarPageState} />}
                            ></Route>
                            <Route path={createPath(RouteName.APARTMENT_EXPENSES)} element={<ApartmentExpensesPage />} />
                            <Route path={createPath(RouteName.SETTINGS)} element={<SettingsPage />}></Route>
                            <Route path={createPath(RouteName.SUBSCRIPTIONS)} element={<SubscriptionsPage />}></Route>
                            <Route path={createPath(RouteName.RECIPES)} element={<RecipesPage />} />
                            <Route path={createPath(RouteName.CHECKLIST)} element={<ChecklistPage />} />
                            <Route path={createPath(RouteName.DARTS)} element={<DartsPage />} />
                            <Route path={createPath(RouteName.SPICES)} element={<SpicesPage />} />
                            <Route path="*" element={<NotFoundPage />} />
                        </Routes>
                    </div>
                    <Footer />
                </BrowserRouter>
            </CommonContext.Provider>
        );
    }
}

function createPath(routeName: RouteName, path?: string): string {
    if (!isAdminOrLoggedUser() && !PUBLIC_ROUTES.includes(routeName)) {
        return 'null';
    }

    if (path == null) {
        return routeName;
    }
    return `${routeName}/${path}`;
}

export default App;
