import {toDateString} from '@mp/common/utils/converters';
import {currentDate} from '@mp/common/utils/date';
import {isEmpty} from '@mp/common/utils/array';
import {CalendarDate, CalendarRepeatType, Grid} from '../types';
import {getEventNamesInDaySpan} from './getEventNamesInDaySpan';
import {resolveFullEventName} from './resolveFullEventName';
import {resolveGrid} from '../gridUtils';

export function resolveCalendarEventsInNearestDays(calendarDates: Array<CalendarDate>): Map<string, string> {
    if (isEmpty(calendarDates)) {
        return new Map<string, string>();
    }

    const grid: Grid = resolveGrid(currentDate(), calendarDates);
    const gridFor3Days: Grid = getCalendarEventsIn(grid, 3);

    gridFor3Days.forEach((cell) => {
        const dts: Array<CalendarDate> = [];
        cell.dates.forEach((date) => {
            if (!date.doneDate) {
                dts.push(date);
            } else if (cell.date.getTime() > date.doneDate.getTime()) {
                dts.push(date);
            }
        });
        cell.dates = dts;
    });

    return new Map<string, string>([
        ['Niewykonane', getOldEventNames(grid)],
        ['Dzisiaj', getEventNamesInDaySpan(gridFor3Days[0]?.dates ?? [])],
        ['Jutro', getEventNamesInDaySpan(gridFor3Days[1]?.dates ?? [])],
        ['Pojutrze', getEventNamesInDaySpan(gridFor3Days[2]?.dates ?? [])]
    ]);
}

function getCalendarEventsIn(grid: Grid, days: number): Grid {
    return grid.filter(({date}) => date.getTime() >= currentDate().getTime()).slice(0, days);
}

function getOldEventNames(grid: Grid): string {
    const calendarDates: Array<CalendarDate> = [];
    grid.filter(({date}) => date.getTime() < currentDate().getTime()).forEach((cell) => {
        calendarDates.push(...cell.dates);
    });

    const repeatNoneDates: Array<CalendarDate> = calendarDates.filter(({repeat, dateTo}) => repeat === CalendarRepeatType.NONE && !dateTo);
    const repeatMonthExactDayDates: Array<CalendarDate> = calendarDates.filter(
        ({repeat, date, doneDate}) => repeat === CalendarRepeatType.MONTH_EXACT_DAY && (doneDate?.getTime() ?? 1) < date.getTime()
    );

    return [...repeatNoneDates, ...repeatMonthExactDayDates]
        .map((item) => `[${toDateString(item.date)}] ${resolveFullEventName(item)}`)
        .join(' | ');
}
