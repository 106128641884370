import {MONTH_NAMES} from '../consts';
import {switchGuard} from './switchGuard';

/**
 * format example: 2015-12-31 00:00:00
 */
export function toDateTime(dateString: string): Date {
    const s = dateString
        .replace(/[ :]/g, '-')
        .split('-')
        .map((d) => Number(d));
    return new Date(s[0], s[1] - 1, s[2], s[3], s[4], s[5]);
}

export function toDate(dateString: string): Date {
    if (!dateString) {
        return null;
    }
    const s = dateString
        .replace(/[ :]/g, '-')
        .split('-')
        .map((d) => Number(d));
    return new Date(s[0], s[1] - 1, s[2], 0, 0, 0);
}

type DateString =
    | 'dd.mm.yyyy'
    | 'dd month yyyy'
    | 'mm.yyyy'
    | 'yyyy-mm-dd'
    | 'dd-mm-yyyy'
    | 'yyyymmdd'
    | 'HH:MM'
    | 'dd.mm'
    | 'yyyy-mm-dd HH:MM:SS';
export function toDateString(date: Date, dateString: DateString = 'yyyy-mm-dd'): string {
    const [yyyy, month, day, hours, minutes, seconds] = [
        date.getFullYear(),
        date.getMonth() + 1,
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
    ];
    const dd: string = zeroPrefix(day);
    const mm: string = zeroPrefix(month);
    const HH: string = zeroPrefix(hours);
    const MM: string = zeroPrefix(minutes);
    const SS: string = zeroPrefix(seconds);

    switch (dateString) {
        case 'dd.mm':
            return `${dd}.${mm}`;
        case 'HH:MM':
            return `${zeroPrefix(date.getHours())}:${zeroPrefix(date.getMinutes())}`;
        case 'yyyy-mm-dd':
            return `${yyyy}-${mm}-${dd}`;
        case 'dd-mm-yyyy':
            return `${dd}-${mm}-${yyyy}`;
        case 'dd.mm.yyyy':
            return `${dd}.${mm}.${yyyy}`;
        case 'dd month yyyy':
            return `${day} ${MONTH_NAMES[month - 1]} ${yyyy}`;
        case 'yyyy-mm-dd HH:MM:SS':
            return `${yyyy}-${mm}-${dd} ${HH}:${MM}:${SS}`;
        case 'yyyymmdd':
            return `${yyyy}${mm}${dd}`;
        case 'mm.yyyy':
            return `${mm}.${yyyy}`;
        default:
            switchGuard(dateString);
    }
}

export function toDateInput(date: Date): string {
    if (date == null) {
        return null;
    }
    return toDateString(date, 'yyyy-mm-dd');
}

export function toDateTimeInput(date: Date): string {
    if (date == null) {
        return null;
    }
    return toDateString(date, 'yyyy-mm-dd HH:MM:SS');
}

export function toTimeInput(time: string): string {
    if (time == null || time === '') {
        return null;
    }
    const splitTime: Array<string> = time.split(':');
    return `${splitTime[0]}:${splitTime[1]}`;
}

export function toFloat(text: string): number {
    if (!text) {
        return null;
    }

    const value: number = Number.parseFloat(text.replace(',', '.'));
    if (!value && value !== 0) {
        return null;
    }
    return value;
}

export function toBoolean(value: string | number | boolean | null | undefined): boolean {
    if (value === 'true') {
        return true;
    }

    return typeof value === 'string'
        ? !!+value // we parse string to integer first
        : !!value;
}

export function booleanToString(bool: boolean): string {
    return toBoolean(bool) ? 'true' : 'false';
}

function zeroPrefix(n: number | string): string {
    if (Number(n) < 10) {
        return '0' + n;
    }
    return '' + n;
}
